const colorByType = {
  full: { left: "#c51861", right: "#c51861" },
  halfFull: { left: "#c51861", right: "#dfe1e4" },
  empty: { left: "#dfe1e4", right: "#dfe1e4" },
};

type StarIconProps = {
  type: "full" | "halfFull" | "empty";
  size?: number;
};

export const StarIcon = ({ type, size = 30 }: StarIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 14 13"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_1169"
          data-name="Rectangle 1169"
          width="8"
          height="13"
          transform="translate(66.496)"
          fill="none"
        />
      </clipPath>
    </defs>
    <g id="etoile_1_2" data-name="etoile 1/2" transform="translate(-66.496)">
      <g id="etoiles" transform="translate(67.864)">
        <g
          id="invisible_box"
          data-name="invisible box"
          transform="translate(0)"
        >
          <rect
            id="Rectangle_1141"
            data-name="Rectangle 1141"
            width="13"
            height="13"
            transform="translate(-0.368)"
            fill="none"
          />
        </g>
        <g
          id="icons_Q2"
          data-name="icons Q2"
          transform="translate(0.119 0.797)"
        >
          <path
            id="Tracé_11225"
            data-name="Tracé 11225"
            d="M7.864,3a.558.558,0,0,0-.478.292L5.871,6.375l-3.4.478a.558.558,0,0,0-.292.93l2.445,2.365-.558,3.4a.531.531,0,0,0,.5.611l.266-.053,3.029-1.594,3.029,1.594.266.053a.531.531,0,0,0,.5-.611l-.558-3.4L13.55,7.783a.558.558,0,0,0-.292-.93l-3.4-.478L8.342,3.292A.558.558,0,0,0,7.864,3Z"
            transform="translate(-2.025 -3)"
            fill={colorByType[type].right}
          />
        </g>
      </g>
      <g
        id="Groupe_de_masques_38"
        data-name="Groupe de masques 38"
        clipPath="url(#clip-path)"
      >
        <g id="etoiles-2" data-name="etoiles" transform="translate(67.864)">
          <g
            id="invisible_box-2"
            data-name="invisible box"
            transform="translate(0)"
          >
            <rect
              id="Rectangle_1141-2"
              data-name="Rectangle 1141"
              width="13"
              height="13"
              transform="translate(-0.368)"
              fill="none"
            />
          </g>
          <g
            id="icons_Q2-2"
            data-name="icons Q2"
            transform="translate(0.119 0.797)"
          >
            <path
              id="Tracé_11225-2"
              data-name="Tracé 11225"
              d="M7.864,3a.558.558,0,0,0-.478.292L5.871,6.375l-3.4.478a.558.558,0,0,0-.292.93l2.445,2.365-.558,3.4a.531.531,0,0,0,.5.611l.266-.053,3.029-1.594,3.029,1.594.266.053a.531.531,0,0,0,.5-.611l-.558-3.4L13.55,7.783a.558.558,0,0,0-.292-.93l-3.4-.478L8.342,3.292A.558.558,0,0,0,7.864,3Z"
              transform="translate(-2.025 -3)"
              fill={colorByType[type].left}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
