import { StarIcon } from "@/public/icons/StarIcon";
import styles from "./StarsRating.module.scss";

type StarsRatingProps = {
  rating: number | undefined;
  starSize: number;
  spacing: number;
};

const getStarIcon = (starIndex: number, starSize: number, rating?: number) => {
  if (rating != null && rating <= starIndex) {
    return <StarIcon type="empty" size={starSize} />;
  }

  if (rating != null && rating >= starIndex + 1) {
    return <StarIcon type="full" size={starSize} />;
  }

  return <StarIcon type="halfFull" size={starSize} />;
};

const STARS_TO_SHOW = 5;

export const StarsRating = ({
  rating,
  starSize,
  spacing,
}: StarsRatingProps) => {
  const roundedRating = rating;

  return (
    <div className={styles.ratingStarsContainer}>
      {Array.from({ length: STARS_TO_SHOW }, (_, index) => (
        <div
          key={index}
          style={{ marginRight: index !== STARS_TO_SHOW - 1 ? spacing : 0 }}
        >
          {getStarIcon(index, starSize, roundedRating)}
        </div>
      ))}
    </div>
  );
};
