import Link from "next/link";
import styles from "./PromoBanners.module.scss";

type WithClickableContainerProps = {
  children: React.ReactNode;
  url?: string;
};

export const WithClickableContainer = ({
  children,
  url,
}: WithClickableContainerProps) => {
  if (url !== undefined) {
    return (
      <Link href={url}>
        <a className={styles.promoContainer}>{children}</a>
      </Link>
    );
  }

  return <div className={styles.promoContainer}>{children}</div>;
};
