import Image from "next/image";
import { useIntl } from "react-intl";
import { useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useState } from "react";
import avisVerifiesLogo from "@/public/images/avisVerifiesLogo.png";
import { ArrowIcon } from "@/public/icons/ArrowIcon";
import { getBreakpoint } from "@/utils/mantine";
import { Section } from "@/components/common/section/Section";
import styles from "./ReviewsBlock.module.scss";
import { StarsRating } from "./ratingInStars/StarsRating";
import { SmallReviewBlock } from "./smallReviewBlock/SmallReviewBlock";
import { MainReviewBlock } from "./mainReviewBlock/MainReviewBlock";
import { LegalReviewText } from "./legalReviewText/LegalReviewText";
import { StructuredReviewData } from "../structuredData/StructuredData";
import { StructuredReviewProps } from "../structuredData/StructuredData.interface";

export type ClientReview = {
  rating: number;
  text: string;
  author: string;
  date: Date;
  agencyName: string;
  agencySlug: string;
};

export type ReviewsConst = {
  averageRating: number;
  numberOfRatings: number;
};

export type RentACarReview = {
  clientReviews: ClientReview[];
};

export const ReviewsBlock = ({
  name,
  agency,
  rentACarReview,
  reviewsConst,
  image,
}: StructuredReviewProps) => {
  const intl = useIntl();
  const NUMBER_OF_REVIEWS_SHOWN = rentACarReview.clientReviews.length;
  const rating = reviewsConst?.averageRating;
  const theme = useMantineTheme();
  const arrowIconColor = theme.colors.gunmetal[0];
  const isTabletOrDesktop = useMediaQuery(
    getBreakpoint(theme.breakpoints.md),
    true
  );

  const [mainReviewIndex, setMainReviewIndex] = useState(0);
  const leftReviewIndex =
    (mainReviewIndex - 1 + NUMBER_OF_REVIEWS_SHOWN) % NUMBER_OF_REVIEWS_SHOWN;
  const rightReviewIndex = (mainReviewIndex + 1) % NUMBER_OF_REVIEWS_SHOWN;

  const decrementReviewIndex = () => {
    setMainReviewIndex(leftReviewIndex);
  };

  const incrementReviewIndex = () => {
    setMainReviewIndex(rightReviewIndex);
  };

  return (
    <>
      <StructuredReviewData
        name={name}
        agency={agency}
        rentACarReview={rentACarReview}
        reviewsConst={reviewsConst}
        image={image}
      />
      <Section containerStyle={styles.reviewsSectionColor}>
        <div className={styles.reviewsBlock}>
          <p className={styles.reviewsBlockTitle}>
            {intl.formatMessage({ id: "reviewsBlockTitle" })}
          </p>

          <div className={styles.mobileNavigationContainer}>
            {!isTabletOrDesktop && (
              <button
                className={styles.leftArrowContainer}
                onClick={decrementReviewIndex}
              >
                <ArrowIcon color={arrowIconColor} size={24} />
              </button>
            )}

            <div className={styles.reviewsStatsContainer}>
              <div className={styles.ratingsContainer}>
                <p className={styles.averageRating}>{rating?.toFixed(1)}/5</p>
                <StarsRating rating={rating} starSize={13} spacing={5} />
              </div>
              <div className={styles.reviewsNumberContainer}>
                <Image
                  src={avisVerifiesLogo}
                  alt=""
                  layout="fixed"
                  width={45}
                  height={12}
                />
                <p className={styles.reviewsNumber}>
                  {intl.formatMessage(
                    { id: "reviewsNumber" },
                    { reviewsNumber: reviewsConst?.numberOfRatings }
                  )}
                </p>
              </div>
            </div>

            {!isTabletOrDesktop && (
              <button
                className={styles.rightArrowContainer}
                onClick={incrementReviewIndex}
              >
                <ArrowIcon color={arrowIconColor} size={24} />
              </button>
            )}
          </div>

          <div className={styles.reviewsContainer}>
            {isTabletOrDesktop && (
              <>
                <button
                  className={styles.leftArrowContainer}
                  onClick={decrementReviewIndex}
                >
                  <ArrowIcon color={arrowIconColor} size={24} />
                </button>

                <div className={styles.leftReviewContainer}>
                  <SmallReviewBlock
                    {...rentACarReview.clientReviews[leftReviewIndex]}
                  />
                </div>
              </>
            )}

            <MainReviewBlock
              {...rentACarReview.clientReviews[mainReviewIndex]}
            />

            {!isTabletOrDesktop && <LegalReviewText />}

            {isTabletOrDesktop && (
              <>
                <div className={styles.rightReviewContainer}>
                  <SmallReviewBlock
                    {...rentACarReview.clientReviews[rightReviewIndex]}
                  />
                  <LegalReviewText />
                </div>

                <button
                  className={styles.rightArrowContainer}
                  onClick={incrementReviewIndex}
                >
                  <ArrowIcon color={arrowIconColor} size={24} />
                </button>
              </>
            )}
          </div>
        </div>
      </Section>
    </>
  );
};

export default ReviewsBlock;
