import Link from "next/link";
import { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import styles from "./LegalReviewText.module.scss";

export const LegalReviewText = () => (
  <p className={styles.legalText}>
    <FormattedMessage
      id="reviewsLegalText"
      values={{
        b: (chunks: ReactNode[]) => (
          <span className={styles.boldLegalText}>{chunks}</span>
        ),
        link: () => (
          <Link href="https://www.avis-verifies.com/avis-clients/rentacar.fr">
            <a className={styles.link} target="_blank">
              Avis-vérifiés
            </a>
          </Link>
        ),
      }}
    />
  </p>
);
