import dayjs from "dayjs";
import { Review } from "@/interfaces/Review";
import styles from "./SmallReviewBlock.module.scss";

export const SmallReviewBlock = ({
  text,
  date,
  author,
  agencyName,
}: Review) => (
  <div className={styles.smallReviewBlock}>
    <p className={styles.reviewText}>« {text} »</p>
    <div className={styles.reviewDetailsContainer}>
      <p className={styles.reviewAuthor}>
        {`${author}. (${dayjs(date).tz("Europe/Paris").format("DD/MM/YYYY")})`}
      </p>
      <span className={styles.reviewAgency}>{agencyName}</span>
    </div>
  </div>
);
