import classnames from "classnames";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CrossIcon } from "@/public/icons/CrossIcon";
import styles from "./PromoBanners.module.scss";
import { WithClickableContainer } from "./ClickableContainer";

enum PromoCategory {
  GOOD_DEAL = "goodDeal",
  NEW = "new",
  INFO = "info",
}

export type PromoBanner = {
  description: string;
  promoCategory: PromoCategory;
  url: string | null;
};

type PromoBannersProps = {
  promoBanners: PromoBanner[];
};

const BANNER_CHANGE_DELAY = 5000;

const isUrlDefined = (
  promoBanner: PromoBanner
): promoBanner is PromoBanner & { url: string } =>
  promoBanner.url !== null && promoBanner.url !== "";

export const PromoBanners = ({ promoBanners }: PromoBannersProps) => {
  const intl = useIntl();

  const [isDisplayed, setIsDisplayed] = useState(true);
  const [promoIndex, setPromoIndex] = useState(0);
  const [intervalId, setIntervalId] = useState<number>();

  useEffect(() => {
    const interval = window.setInterval(() => {
      setPromoIndex((i) => (i + 1) % promoBanners.length);
    }, BANNER_CHANGE_DELAY);
    setIntervalId(interval);

    return () => clearInterval(interval);
  }, [promoBanners]);

  const promoBanner = promoBanners[promoIndex];

  const removeBanner = () => {
    setIsDisplayed(false);
    clearInterval(intervalId);
  };

  if (!isDisplayed) {
    return null;
  }

  return (
    <div className={styles.bannerContainer}>
      <WithClickableContainer
        url={isUrlDefined(promoBanner) ? promoBanner.url : undefined}
      >
        <div
          className={classnames(styles.categoryContainer, {
            [styles.goodDealContainer]:
              promoBanner.promoCategory === PromoCategory.GOOD_DEAL,
            [styles.newContainer]:
              promoBanner.promoCategory === PromoCategory.NEW,
            [styles.infoContainer]:
              promoBanner.promoCategory === PromoCategory.INFO,
          })}
        >
          <p className={styles.categoryLabel}>
            {intl.formatMessage({ id: promoBanner.promoCategory })}
          </p>
        </div>
        <p className={styles.promoDescription}>{promoBanner.description}</p>
      </WithClickableContainer>
      <button className={styles.crossButton} onClick={removeBanner}>
        <CrossIcon size={25} />
      </button>
    </div>
  );
};
