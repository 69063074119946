import dayjs from "dayjs";
import Head from "next/head";

import { StaticImageData } from "next/image";
import { StrapiEntity, StrapiImage } from "@/utils/strapi";
import {
  StructuredBreadcrumbProps,
  StructuredFAQItem,
  StructuredFAQProps,
  StructuredReviewProps,
} from "./StructuredData.interface";

export const StructuredBreadcrumbData = ({
  itemListElement,
}: StructuredBreadcrumbProps) => {
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: itemListElement,
          }),
        }}
      />
    </Head>
  );
};

export const StructuredFAQData = ({ rawData }: StructuredFAQProps) => {
  const faqData: StructuredFAQItem[] = rawData.map((qAndA) => {
    return {
      "@type": "Question",
      name: qAndA.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: qAndA.answer,
      },
    };
  });

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: faqData,
          }),
        }}
      />
    </Head>
  );
};

export const StructuredReviewData = ({
  name,
  agency,
  rentACarReview,
  reviewsConst,
  image,
}: StructuredReviewProps) => {
  name = name === undefined ? "Rentacar" : name;

  const getStructuredReviews = () => {
    const structuredReviews: unknown[] = [];
    rentACarReview.clientReviews.map((review) =>
      structuredReviews.push({
        "@type": "Review",
        author: {
          "@type": "Person",
          name: review.author,
        },
        datePublished: review.date,
        description: review.text,
        reviewRating: {
          "@type": "Rating",
          bestRating: 5,
          ratingValue: review.rating,
          worstRating: 1,
        },
      })
    );

    return structuredReviews;
  };

  const getAddress = () => {
    if (agency !== undefined) {
      return {
        "@type": "PostalAddress",
        streetAddress: agency.addressDto.street,
        addressLocality: agency.addressDto.city,
        addressRegion: "",
        postalCode: agency.addressDto.zipCode,
        addressCountry: agency.addressDto.country,
      };
    } else {
      return {
        "@type": "PostalAddress",
        streetAddress: "1 Quai Gabriel Pery",
        addressLocality: "Joinville-le-pont",
        addressRegion: "",
        postalCode: "94340",
        addressCountry: "France",
      };
    }
  };

  const addMinutes = (date: string, minutes: number) => {
    return dayjs(date, { utc: true })
      .add(minutes, "minutes")
      .format("HH:mm:ss")
      .toString();
  };

  const getOpeningHours = () => {
    if (agency !== undefined) {
      const openingHours: Array<string> = [];
      agency.nextSchedules.map((schedule) => {
        schedule.slots.map((slot) => {
          const endReal = addMinutes(
            schedule.day.split("T")[0] + "T" + slot.end,
            15
          );
          const day = dayjs(schedule.day, { utc: true })
            .locale("en")
            .format("dd");
          openingHours.push(
            day.toUpperCase() +
              " " +
              slot.start.replace(":00", "") +
              " h - " +
              endReal.replace(":00", "") +
              " h"
          );
        });
      });

      return openingHours;
    } else {
      return [];
    }
  };

  const getImage = (): string | undefined => {
    if (image !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if ((image as StrapiEntity<StrapiImage>).data !== undefined) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
        return (image as StrapiEntity<StrapiImage>).data.attributes.url;
      } else {
        return `https://${process.env.NEXT_PUBLIC_DOMAIN ?? ""}${
          (image as StaticImageData).src
        }`;
      }
    }
  };

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "AutoRental",
            name: name,
            openingHours: getOpeningHours(),
            telephone: agency?.communicationMeansDto.phone ?? "01 80 51 37 00",
            address: getAddress(),
            image: getImage(),
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: reviewsConst?.averageRating.toString(),
              bestRating: "5",
              worstRating: "1",
              ratingCount: reviewsConst?.numberOfRatings,
            },
            reviews: getStructuredReviews(),
          }),
        }}
      />
    </Head>
  );
};
